<div class="container-fluid">
  <div class="d-sm-flex align-items-center justify-content-between mb-3">
    <div
      class="d-flex flex-column align-items-start"
    >
      <div
        *ngIf="!editingNickname; else editName"
        class="d-flex flex-row align-items-center"
      >
        <h1 class="text-gray-800 text-break">{{thing?.attributes?.nickname || thingName}}</h1>
        <i
          class="fas fa-pen ml-2 clickable"
          (click)="editingNickname = true"
          *canEditNickname
          ngbTooltip="Edit nickname"
        ></i>
        <app-audit-creator
          [type]="AuditType.THING"
          [action]="AuditAction.NICKNAMED"
          [resourceId]="thingName"
          phrasing="named by"
        />
      </div>
      @if (thing?.attributes?.nickname) {
        <h2 class="text-muted h6">{{thingName}}</h2>
      }
    </div>
    <div class="float-right btn-upgrade">
      <a
        *canDeployOnOneThing
        class="btn btn-primary pull-right"
        routerLink="/metaversions/thing/{{ thingName }}"
      >
        <i aria-hidden="true" class="fas fa-cloud-upload-alt"></i>
        Upgrade Firmware
      </a>
      <button
        (click)="openDeleteDialog()"
        *canDeleteThing
        class="btn btn-danger pull-right ml-2"
      >
        <i aria-hidden="true" class="fas fa-trash"></i> Delete thing
      </button>
    </div>
  </div>
  <app-tab-view>
    <app-tab tabId="details" tabTitle="General information">
      <ng-template #tabContent>
        <div class="card shadow mb-4">
          <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-primary">
              General Information
              <button (click)="refresh()" class="btn btn-primary btn-sm ml-3">
                <i aria-hidden="true" class="fas fa-sync"></i>
              </button>
            </h6>
          </div>
          <div class="card-body">
            <div *ngIf="this.thing; else elseBlock" class="table-responsive">
              <table class="table table-bordered" id="dataTable">
                <tbody>
                  <tr>
                    <th scope="row">Appliance</th>
                    <td>
                      {{
                        this.product?.description ?? 'Unknown'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Connection State</th>
                    <td>
                      {{
                        this.shadow.connected() ? "Connected" : "Not connected"
                      }}
                    </td>
                  </tr>
                  @if (this.shadow.synchronizing() !== undefined) {
                    <tr>
                      <th scope="row">Synchronizing</th>
                      <td>
                        {{ this.shadow.synchronizing() ? "Yes" : "No" }}
                      </td>
                    </tr>
                  }
                  @if (creationDate !== undefined) {
                    <tr>
                      <th scope="row">First Connection</th>
                      <td>
                        {{ creationDate  | withMsDate | date : "yyyy-MM-dd HH:mm:ss" }}
                      </td>
                    </tr>
                  }
                  <tr>
                    <th scope="row">Last Connection</th>
                    <td>
                      {{ shadow.lastConnection() | displayMetaVersionDate }}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Last Disconnection</th>
                    <td>
                      {{ shadow.lastDisconnection() | displayMetaVersionDate }}
                    </td>
                  </tr>
                  @if (shadow.firmwareUpdateDate() !== undefined) {
                    <tr>
                      <th scope="row">Firmware update date</th>
                      <td>
                        {{
                          shadow.firmwareUpdateDate() === 0
                            ? "Never"
                            : (shadow.firmwareUpdateDate()! | withMsDate
                              | date: "yyyy-MM-dd HH:mm:ss")
                        }}
                      </td>
                    </tr>
                  }
                  <tr>
                    <th scope="row">Current Firmware Version</th>
                    <td>
                      <div>
                        {{
                          shadow.reportedFirmware()
                            ? shadow.reportedFirmware()?.version
                            : "-"
                        }}
                        <small
                          >{{ shadow.reportedFirmware() | displayMetaVersion }}
                        </small>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Next Firmware Version (reported)</th>
                    <td>
                      <div>
                        {{
                          shadow.reportedNextFirmware()
                            ? shadow.reportedNextFirmware()?.version
                            : "-"
                        }}
                        <small>{{
                          shadow.reportedNextFirmware() | displayMetaVersion
                        }}</small>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Next Firmware Version (desired)</th>
                    <td>
                      <div>
                        {{
                          shadow.desiredNextFirmware()
                            ? shadow.desiredNextFirmware()?.version
                            : "-"
                        }}
                        <small
                          >{{
                            shadow.desiredNextFirmware() | displayMetaVersion
                          }}
                        </small>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Updating</th>
                    <td>{{ shadow.updating() ? "Yes" : "No" }}</td>
                  </tr>
                  @if (shadow.firmwareUpdateStatus(); as updateStatus) {
                    <tr>
                      <th scope="row">Last update status reported by thing</th>
                      <td class="p-0">
                        <table>
                          <tr>
                            <th scope="row">
                              Attempted to install metaversion
                            </th>
                            <td>{{ updateStatus.updateMetaversion }}</td>
                          </tr>
                          <tr>
                            <th scope="row">Status</th>
                            <td>{{ updateStatus.updateStatus }}</td>
                          </tr>
                          <tr>
                            <th scope="row">Reason</th>
                            <td>{{ updateStatus.updateReason }}</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  }
                  <tr>
                    <th scope="row">MAC Address</th>
                    <td>{{ this.macAddress || "Loading..." }}</td>
                  </tr>
                  <tr *ngFor="let attributeKey of attributeKeys | keyvalue">
                    <th scope="row">{{ attributeKey.value }}</th>
                    <td>{{ thing.attributes[attributeKey.value] }}</td>
                  </tr>
                  @if (consents$ | async; as consents) {
                    @if (consents.CONNECT) {
                      <tr>
                        <th scope="row">Consent Connect</th>
                        <td>{{ consents.CONNECT | titlecase }}</td>
                      </tr>
                    }
                    @if (consents.ADS) {
                      <tr>
                        <th scope="row">Consent Advertising</th>
                        <td>{{ consents.ADS | titlecase }}</td>
                      </tr>
                    }
                    @if (consents.AUDIENCE) {
                      <tr>
                        <th scope="row">Consent Audience</th>
                        <td>{{ consents.AUDIENCE | titlecase }}</td>
                      </tr>
                    }
                    @if (consents.CUSTOMIZE) {
                      <tr>
                        <th scope="row">Consent Customize</th>
                        <td>{{ consents.CUSTOMIZE | titlecase }}</td>
                      </tr>
                    }
                  }
                  @if (shadow.localizationTimeZone()) {
                    <tr>
                      <th scope="row">Timezone</th>
                      <td>
                        {{ shadow.localizationTimeZone() }}
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
            <ng-template #elseBlock>
              <div class="alert alert-primary">Loading...</div>
            </ng-template>
          </div>
        </div>
        <br />
        <app-realtime [thingName]="thingName ?? undefined"></app-realtime>
      </ng-template>
    </app-tab>
    <app-tab *canListGroupsOfThings tabId="groups" tabTitle="Thing's groups">
      <ng-template #tabContent>
        <div class="card shadow mb-4">
          <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-primary">
              Thing's groups
              <button
                (click)="refreshGroups()"
                class="btn btn-primary btn-sm ml-3"
              >
                <i aria-hidden="true" class="fas fa-sync"></i>
              </button>
              <button
                (click)="addThingToAGroup(thing)"
                *canEditGroupOfThings
                [disabled]="!thing"
                class="btn btn-sm btn-success float-right"
              >
                <i aria-hidden="true" class="fas fa-sitemap"></i>
                &nbsp;Add to group
              </button>
            </h6>
          </div>
          <div class="card-body">
            <div
              *ngIf="groups$ | async as groups; else loading"
              class="table-responsive"
            >
              <app-groups-of-things-list-table
                [actionsTemplate]="tableActionsTemplate"
                [groups]="groups ?? undefined"
              >
                <ng-template #tableActionsTemplate let-group>
                  <div class="btn-group">
                    <a
                      class="btn btn-eye"
                      routerLink="/groups/{{ group.groupId }}"
                    >
                      <i aria-hidden="true" class="fas fa-eye"></i>
                    </a>
                    <a
                      (click)="removeThingFromGroup(group, thing)"
                      class="btn btn-danger"
                      ngbTooltip="Remove from group"
                    >
                      <i aria-hidden="true" class="fas fa-minus-circle"></i>
                    </a>
                  </div>
                </ng-template>
              </app-groups-of-things-list-table>
            </div>
          </div>
        </div>
      </ng-template>
    </app-tab>
    <app-tab *canReadProductLogs tabId="logs" tabTitle="Product logs">
      <ng-template #tabContent>
        <app-product-logs
          [mac]="macAddress"
          [thingname]="thingName ?? undefined"
        ></app-product-logs>
      </ng-template>
    </app-tab>
  </app-tab-view>
</div>

<ng-template #loading>
  <app-spinner></app-spinner>
</ng-template>


<ng-template #editName>
  <form class="form-inline flex flex-column align-items-start gap-1" (ngSubmit)="saveNickname()">
    <div class="input-group mr-2">
      <input
        type="text"
        class="form-control"
        [formControl]="nicknameControls"
      />
      <button
        type="submit"
        class="btn btn-outline-primary"
        [disabled]="
              nicknameControls.pristine ||
              nicknameControls.invalid ||
              editNicknameLoading"
      >
        <i class="fas fa-check" aria-hidden="true"></i>
      </button>
      <button
        type="button"
        class="btn btn-outline-secondary"
        (click)="cancelNameEdit()"
        [disabled]="editNicknameLoading"
      >
        <i class="fas fa-times" aria-hidden="true"></i>
      </button>
      <app-spinner-small *ngIf="editNicknameLoading"></app-spinner-small>
    </div>
    <div class="text-danger" *ngIf="nicknameControls.errors && nicknameControls.touched">
      <p *ngIf="nicknameControls.hasError('required')" class="mb-0">Can't be empty</p>
      <p *ngIf="nicknameControls.hasError('pattern')" class="mb-0">Can't have whitespaces, accents, or other symbols than : _ . , &#64; / : # -</p>
      <p *ngIf="nicknameControls.hasError('maxlength')" class="mb-0">Can't be longer than 100 characters</p>
    </div>
  </form>
</ng-template>
