<!-- Begin Page Content -->
<div class="container-fluid">
  <!-- Page Heading -->
  <h1 class="text-gray-800">Devices</h1>
  <!-- Page Body -->
  <div class="card shadow mb-4">
    <!-- Begin of filter bar -->
    <div class="card-header">
      <div class="d-flex flex-row justify-content-between w-100">
        <form action="" class="form-inline">
          <div class="form-row align-items-center g-3">
            <div class="col-auto ml-1">
              <select
                class="form-select"
                aria-label="Default select example"
                id="thingType"
                name="thingType"
                [disabled]="isLoading"
                [(ngModel)]="filterValue"
                (change)="onChangeTypeThing($event)"
              >
                <option *ngFor="let filter of filters">
                  {{ filter.name }}
                </option>
              </select>
            </div>
            <div class="col-auto">
              <div class="form-check form-switch form-check-inline">
                <input
                  type="checkbox"
                  id="connected"
                  name="connected"
                  [disabled]="isLoading"
                  [(ngModel)]="connected"
                  class="form-check-input"
                  (change)="onChange()"
                />
                <label class="form-check-label" for="connected">
                  Connected devices
                </label>
              </div>
            </div>
            <div class="col-auto">
              <button class="btn btn-primary" (click)="resetFilter()">
                Reset filters
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!-- end of filter bar -->
    <!-- Begin content -->
    <app-thing-list-table
      [devices]="devices"
      [connected]="connected"
      [thingType]="filterValue"
      [formGroupLocalFilters]="formGroupLocalFilters"
      [columns]="columns"
      [configuration]="configuration"
      [pageNumber]="pageNumber"
      [isLoading]="isLoading"
      [thingsCount$]="thingsCount$"
      [disablePreviousPage]="disablePreviousPage"
      [disableNextPage]="disableNextPage"
      [previousDevices]="previousDevices.bind(this)"
      [nextDevices]="nextDevices.bind(this)"
    >
    </app-thing-list-table>
  </div>
</div>
