import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuditAction, AuditType } from '@common/audit-log/models/AuditLog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as FileSaver from 'file-saver';
import { lastValueFrom, Observable, of } from 'rxjs';
import {
  catchError,
  map,
  mapTo,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import CONFIG from '../../config';
import { AuditService } from '../api/backend/services/audit/audit.service';
import { FirmwareFileService } from '../api/backend/services/firmware-file/firmware-file.service';
import { FirmwareService } from '../api/backend/services/firmware/firmware.service';
import { DatabaseService } from '../api/database.service';
import { NavigationService } from '../lib/navigation.service';
import { StoreService } from '../lib/store.service';
import { BrandArea, BrandAreaKey } from '../models/brandarea';
import {
  CriteriaKey,
  CriteriaType,
  Firmware,
  FirmwareFileWrapper,
  IndiceKey,
  ParsedCriteriaKey,
  RangeKey,
  S3Value,
} from '../models/firmware';
import { NotificationService } from '../shared/notification.service';

@Component({
  selector: 'app-firmwarefile',
  templateUrl: './firmwarefile.component.html',
  styleUrls: ['./firmwarefile.component.css'],
})
export class FirmwarefileComponent implements OnInit {
  @ViewChild('fileInput') uploadInput?: ElementRef;
  @ViewChild('indiceInput') indiceInput?: ElementRef;
  @ViewChild('confirmClearShadowsModal') confirmClearShadowsModal?: ElementRef;

  firmware?: Firmware;
  firmwareId?: string | null;
  brandAreas: BrandArea[] = [];
  isLoading = false;
  firmwareSigned = false;

  mustHaveRange = false;
  mustHaveCmmf = false;
  mustHaveIndice = false;
  shouldDisplayBrandArea = false;

  additionalCmmfs: string[] = [];

  extractingFirmwareData = false;
  firmwareSourceFilename?: string;
  firmwareTemporaryFilename?: string;

  loadingPresignUrl$: Observable<boolean> = of(false);

  readonly AuditType = AuditType;
  readonly AuditAction = AuditAction;

  constructor(
    private readonly router: Router,
    private readonly store: StoreService,
    private readonly notif: NotificationService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly databaseService: DatabaseService,
    private readonly firmwareFileService: FirmwareFileService,
    private readonly navigationService: NavigationService,
    private readonly firmwareService: FirmwareService,
    private readonly auditService: AuditService,
    private readonly modalService: NgbModal,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(async (params) => {
      await this.fetchFirmware(params.get('firmwarefileId') as string);
      this.firmwareId = params.get('firmwarefileId');
    });
  }

  extractFirmwareData(): void {
    if (this.firmware?.type === 'wifi') {
      return;
    }

    const file = this.uploadInput?.nativeElement.files?.[0];
    if (!file) {
      return;
    }
    this.extractingFirmwareData = true;
    const sourceFilename = file.name;

    this.firmwareFileService
      .extractFirmwareData(file, this.firmware?.thingType)
      .subscribe(
        ({ tempFilename, extractedData }) => {
          console.log(
            'Extracted data from firmware',
            sourceFilename,
            tempFilename,
            extractedData,
          );
          this.firmwareSourceFilename = sourceFilename;
          this.firmwareTemporaryFilename = tempFilename;

          // Only TechnicalIncrement is handled for now
          if (this.firmwareFileService.hasExtractedData(extractedData)) {
            if (
              extractedData.technicalIncrement !== undefined &&
              extractedData.technicalIncrement >= 0 &&
              this.indiceInput
            ) {
              this.indiceInput.nativeElement.value =
                extractedData.technicalIncrement;
              this.indiceInput.nativeElement.dispatchEvent(new Event('change'));
            }
          } else {
            this.notif.showInfo(
              'No data could be extracted from the given Firmware file',
            );
          }

          this.extractingFirmwareData = false;
        },
        (error) => {
          console.error(error);
          this.notif.showWarning(
            `Couldn't extract data from Firmware file : ${error?.message}`,
          );
          this.extractingFirmwareData = false;
        },
      );
  }

  async saveFirmare(): Promise<void> {
    if (this.isLoading) {
      return;
    }

    if (!this?.firmware?.newS3Key) {
      this.notif.showError('Firmware not found!');
      return;
    }

    if (
      this.brandAreas.length !== 0 &&
      (!this.firmware.newS3Key.brandArea ||
        this.firmware.newS3Key.brandArea === 'NA')
    ) {
      this.notif.showError('Brand Area is missing');
      return;
    }

    if (this.firmware.criteriaType !== CriteriaType.THINGTYPE) {
      if (this.mustHaveRange && !this.firmware.newS3Key.range) {
        this.notif.showError('Range is missing');
        return;
      }
      if (this.mustHaveCmmf && !this.firmware.newS3Key.cmmf) {
        this.notif.showError('CMMF is missing');
        return;
      }
      if (this.mustHaveIndice && !this.firmware.newS3Key.indice) {
        this.notif.showError('Indice is missing');
        return;
      }
      if (
        this.firmware.newS3Key.range?.includes('_') ||
        this.firmware.newS3Key.cmmf?.includes('_') ||
        this.firmware.newS3Key.indice?.includes('_')
      ) {
        this.notif.showError(
          'Refrain from using the character "_" in range, CMMF or indice',
        );
        return;
      }
    }

    if (
      this.firmware.newS3Key.cmmf &&
      !FirmwareFileService.isCMMFValid(this.firmware.newS3Key.cmmf)
    ) {
      this.notif.showError(
        `CMMF "${this.firmware.newS3Key.cmmf}" should be 10 digits only`,
      );
      return;
    }

    if (this.additionalCmmfs?.length) {
      let invalidCMMFs = false;
      for (const additionalCmmf of this.additionalCmmfs) {
        if (!FirmwareFileService.isCMMFValid(additionalCmmf)) {
          invalidCMMFs = true;
          this.notif.showError(
            `CMMF "${additionalCmmf}" should be 10 digits only`,
          );
        }
      }

      if (invalidCMMFs) {
        return;
      }
    }

    if (
      this.firmware.type === 'ui' &&
      (!this.firmwareSourceFilename || !this.firmwareTemporaryFilename)
    ) {
      console.error(
        'No file name provided',
        this.firmwareSourceFilename,
        this.firmwareTemporaryFilename,
      );
      return;
    }

    this.isLoading = true;

    try {
      let firmwareFileWrapper: FirmwareFileWrapper;

      if (this.firmware.type === 'ui') {
        firmwareFileWrapper = {
          sourceFilename: this.firmwareTemporaryFilename,
          filename: this.firmwareSourceFilename,
          sourceBucket: CONFIG.temporaryFirmwaresBucket,
          targetBucket: CONFIG.firmwaresBucket,
        };
      } else {
        firmwareFileWrapper = {
          file: this.uploadInput?.nativeElement.files?.[0],
          targetBucket: CONFIG.firmwaresBucket,
        };
      }

      this.firmwareFileService
        .updateFirmware(
          this.firmware,
          firmwareFileWrapper,
          this.additionalCmmfs,
        )
        .subscribe(
          async () => {
            this.notif.showSuccess('Firmware updated');
            this.auditService.pushEvent({
              type: AuditType.FIRMWARE,
              action: AuditAction.UPDATE,
              resourceId: this.firmware?.id,
            });
            await this.router.navigateByUrl('/firmwares');
          },
          (e) => {
            console.error(e);
            if (e.code !== 'ConditionalCheckFailedException') {
              this.notif.showError(`An error occurred : "${e.message}`, e);
            }
            this.isLoading = false;
          },
        );
    } catch (e) {
      this.notif.showError((e as Error).message, e);
      this.isLoading = false;
      return;
    }
  }

  async firmwareUpdateState(enable: boolean, id: string): Promise<void> {
    this.isLoading = true;
    if (enable) {
      await this.databaseService.activateFirmware(id);
    } else {
      await this.databaseService.deactivateFirmware(id);
    }
    this.auditService.pushEvent({
      action: enable ? AuditAction.RESTORE : AuditAction.DEPRECATE,
      type: AuditType.FIRMWARE,
      resourceId: id,
    });
    await this.fetchFirmware(id);
    this.isLoading = false;
  }

  async clearNextFirmwareShadows(id: string): Promise<void> {
    this.isLoading = true;
    this.modalService
      .open(this.confirmClearShadowsModal, {
        ariaLabelledBy: 'modal-basic-title',
        backdrop: 'static',
      })
      .result.then(async (confirm) => {
        if (!confirm) {
          return;
        }

        await lastValueFrom(
          this.firmwareService.deleteNextFirmwareShadows(id).pipe(
            tap((_response) => {
              this.notif.showSuccess(
                `Successfully cleared the shadow of "${_response.count}" devices`,
              );
            }),
            catchError((err) => {
              this.notif.showError((err as Error).message, err);
              return of(void 0);
            }),
          ),
        );

        this.isLoading = false;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  onChange(event: Event): void {
    const value = (event.target as HTMLOptionElement).value;
    if (this?.firmware?.newS3Key) {
      this.firmware.newS3Key.brandArea =
        value === '' ? ('NA' as BrandAreaKey) : (value as BrandAreaKey);
    }
  }

  onChangeCheck(event: Event): void {
    const checked = (event.target as HTMLInputElement).checked;
    if (this?.firmware?.newS3Key) {
      this.firmware.newS3Key.isSigned = checked;
      this.firmware.newS3Key.isAlreadySigned = checked;
    }
  }

  onChangeBoatLoader(event: Event): void {
    if (this?.firmware?.newS3Key) {
      this.firmware.newS3Key.bootloader = (
        event.target as HTMLInputElement
      ).value.trim();
    }
  }

  onChangeRange(e: Event): void {
    if (this?.firmware?.newS3Key && this.mustHaveRange) {
      this.firmware.newS3Key.range =
        ((e.target as HTMLInputElement).value.trim() as RangeKey) || undefined;
    }
  }

  onChangeIndice(e: Event): void {
    if (this?.firmware?.newS3Key && this.mustHaveIndice) {
      this.firmware.newS3Key.indice =
        ((e.target as HTMLInputElement).value.trim() as IndiceKey) || undefined;
    }
  }

  /**
   * Adds a CMMF either into the firmware, or to the "additional cmmfs" array
   *
   * @param firmware the firmware to add the CMMF to
   * @param cmmf the added CMMF
   */
  onAddingCMMF(firmware: Firmware, cmmf: string): void {
    this.firmwareFileService.helpHandlingAddingCMMF(
      firmware,
      cmmf,
      this.additionalCmmfs,
    );
  }

  /**
   * Removing a CMMF will either remove it from the firmware or remove it from the "additional cmmfs" array
   * If the removed CMMF is from the firmware, but additional CMMFs exist in the "additional cmmf",
   * then the first element is removed to be set in the Firmware
   *
   * @param firmware The firmware which CMMF to handle (Should be uiFirmware)
   * @param removedCmmf the removed CMMF
   */
  onRemovingCMMF(firmware: Firmware, removedCmmf: string): void {
    this.firmwareFileService.helpHandlingRemovingCMMF(
      firmware,
      removedCmmf,
      this.additionalCmmfs,
    );
  }

  cancel(): void {
    this.navigationService.back('/firmwares');
  }

  onDownloadFirmware(firmware: Firmware): void {
    if (firmware.s3Key == null || !Object.keys(firmware.s3Key).length) {
      return;
    }

    const [firmwareFirstS3Key, { file: s3Filepath }] = Object.entries(
      firmware.s3Key,
    ).shift() as [string, S3Value];
    const filename = s3Filepath.split('/').pop() as string;

    this.loadingPresignUrl$ = this.firmwareService
      .getFirmwareGetPresignUrl(firmware, firmwareFirstS3Key)
      .pipe(
        switchMap((url) => this.firmwareService.downloadFromPresignUrl(url)),
        map((blob) => {
          FileSaver.saveAs(blob, filename);
          return false;
        }),
        catchError((err) =>
          this.notif
            .showError(`Failed to download file ${filename}`, err)
            .pipe(mapTo(false)),
        ),
        startWith(true),
        shareReplay(1),
      );
  }

  private async fetchFirmware(firmwareFileId: string): Promise<void> {
    try {
      const item = await this.databaseService.getFirmware(firmwareFileId);

      this.firmware = Firmware.parse(item, true);
      this.firmwareSigned = this.firmware.isNewSigned();
      this.brandAreas = (await this.databaseService.listBrandAreas()).filter(
        (_) =>
          _.thingType === this.firmware?.thingType &&
          _.firmwareType === this.firmware.type,
      );

      this.mustHaveRange = [
        CriteriaType.RANGE,
        CriteriaType.RANGE_INDICE,
      ].includes(this.firmware.criteriaType);
      this.mustHaveCmmf = [
        CriteriaType.CMMF,
        CriteriaType.CMMF_INDICE,
      ].includes(this.firmware.criteriaType);
      this.mustHaveIndice = [
        CriteriaType.RANGE_INDICE,
        CriteriaType.CMMF_INDICE,
      ].includes(this.firmware.criteriaType);
      this.shouldDisplayBrandArea = Object.keys(this.firmware.s3Key ?? {}).some(
        (s3key) =>
          ParsedCriteriaKey.fromCriteriaKey(s3key as CriteriaKey).brandArea !==
          'NA',
      );
    } catch (e) {
      this.notif.showError((e as Error).message, e);
    }
  }
}
