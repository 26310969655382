<!-- Begin Page Content -->
<div class="container-fluid">
  <!-- Page Heading -->
  <h1 class="text-gray-800">Firmwares</h1>
  <!-- Page Body -->
  <div class="card shadow mb-4">
    <div class="card-header py-3">
      <form action="" class="form-inline">
        <div class="form-row align-items-center">
          <h6 class="m-0 font-weight-bold text-primary">Firmwares List</h6>
          <div class="form-row align-items-center">
            <div class="col-auto ml-4">
              <div class="form-check form-switch">
                <input
                  type="checkbox"
                  id="activated"
                  name="connected"
                  [disabled]="isLoading"
                  class="form-check-input"
                  [formControl]="activeFirmwaresControl"
                />
                <label class="form-check-label" for="activated">
                  Active firmware
                </label>
              </div>
            </div>
            <div class="col-auto ml-2">
              <input
                class="form-control"
                [formControl]="globalSearchInput"
                placeholder="Global search"
              />
            </div>
            <div class="col-auto ml-2">
              <button class="btn btn-primary" (click)="resetFilter()">
                Reset filters
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <app-ngx-table-with-query-params-persistence
          [configuration]="configuration"
          [data]="firmwares"
          [columns]="columns"
          [globalSearchControl]="globalSearchInput"
          [filtersFormGroup]="localFiltersFormGroup"
          [filtersTemplate]="filtersTemplate"
          [strictMatchColumns]="['criteriaType']"
        >
          <ng-template let-untypedFirmware>
            <!-- Allows IDEs to correctly infer the object type to provide completion and checks -->
            <ng-container
              *ngIf="typedSearchableFirmware(untypedFirmware) as firmware"
            >
              <td>{{ firmware.date | displayFormatDate }}</td>
              <td>{{ firmware.thingType }}</td>
              <td class="text-uppercase">{{ firmware.type }}</td>
              <td>
                <a
                  *canSeeFirmwareDetails
                  [ngStyle]="{ color: firmware.activated ? 'green' : 'red' }"
                  class="a-link"
                  routerLink="{{ '/firmwarefile/' + firmware.id }}"
                >
                  {{ firmware | displayMetaVersionWifi }}
                </a>
                <p
                  *canSeeFirmwareDetails="false"
                  [ngStyle]="{ color: firmware.activated ? 'green' : 'red' }"
                >
                  {{ firmware | displayMetaVersionWifi }}
                </p>
              </td>
              <td>{{ firmware.criteriaType || "-" }}</td>
              <td>{{ firmware.brandAreaStr || "-" }}</td>
              <td>{{ (firmware.createdBy | displayCreatorName) || "-" }}</td>
              <td class="btn-group">
                <a
                  class="btn btn-eye"
                  routerLink="{{ '/firmwarefile/' + firmware.id }}"
                  *canSeeFirmwareDetails
                >
                  <i class="fas fa-eye" aria-hidden="true"></i>
                </a>
                <ng-container *canEditFirmware>
                  <button
                    class="btn btn-success center"
                    (click)="firmwareUpdateState(true, firmware.id)"
                    *ngIf="!firmware.activated"
                  >
                    Activate
                  </button>
                  <button
                    class="btn btn-danger center"
                    (click)="firmwareUpdateState(false, firmware.id)"
                    *ngIf="firmware.activated"
                    ngbTooltip="Deprecate"
                  >
                    <i class="fas fa-trash-alt" aria-hidden="true"></i>
                  </button>
                </ng-container>
              </td>
            </ng-container>
          </ng-template>

          <ng-template #filtersTemplate>
            <ng-container [formGroup]="localFiltersFormGroup">
              <th scope="col" class="pl-0 pr-3 pt-0">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Creation date"
                  formControlName="dateStr"
                />
              </th>
              <th scope="col" class="pl-0 pr-3 pt-0">
                <select
                  class="form-select"
                  formControlName="thingType"
                  [disabled]="(thingTypes$ | async) == null"
                >
                  <option [ngValue]="null">All</option>
                  <option
                    *ngFor="let thingType of thingTypes$ | async"
                    [ngValue]="thingType"
                  >
                    {{ thingType }}
                  </option>
                </select>
              </th>
              <th scope="col" class="pl-0 pr-3 pt-0">
                <select class="form-select" formControlName="type">
                  <option [ngValue]="null">All</option>
                  <option ngValue="wifi">WIFI</option>
                  <option ngValue="ui">UI</option>
                </select>
              </th>
              <th scope="col" class="pl-0 pr-3 pt-0">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Firmware files"
                  formControlName="id"
                />
              </th>
              <th scope="col" class="pl-0 pr-3 pt-0">
                <select class="form-select" formControlName="criteriaType">
                  <option [ngValue]="null">All</option>
                  <option ngValue="THINGTYPE">Thing type</option>
                  <option ngValue="RANGE">Range</option>
                  <option ngValue="CMMF">Cmmf</option>
                  <option ngValue="RANGE_INDICE">Range-indice</option>
                  <option ngValue="CMMF_INDICE">Cmmf-indice</option>
                </select>
              </th>
              <th scope="col" class="pl-0 pr-3 pt-0">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Brand Area"
                  formControlName="brandAreaStr"
                />
              </th>
            </ng-container>
          </ng-template>
        </app-ngx-table-with-query-params-persistence>
      </div>
    </div>
  </div>
</div>
