<p class="text-warning" *ngIf="things.length !== group?.numberOfThings">
  If you just added or removed things to this group, you may need to refresh
  this page in a minute for the list to be updated.
</p>
<div class="table-responsive">
  <app-ngx-table-with-query-params-persistence
    [columns]="columns"
    [configuration]="configuration"
    [data]="things"
    [filtersFormGroup]="formGroup"
    [filtersTemplate]="thingsFilters"
    prefix="things"
  >
    <ng-template let-rawThing>
      <ng-container *ngIf="typeThing(rawThing) as thing">
        <td>{{ thing.name }}</td>
        <td>{{ thing.connectionTimeStr }}</td>
        <td>{{ thing.currentFirmware }}</td>
        <td>{{ thing.nextFirmwareReported }}</td>
        <td>{{ thing.nextFirmwareDesired }}</td>
        <td>
          <div class="btn-group">
            <a class="btn btn-eye" routerLink="/things/{{ thing.name }}">
              <i class="fas fa-eye" aria-hidden="true"></i>
            </a>
            <a
              class="btn btn-primary"
              routerLink="/metaversions/thing/{{ thing.name }}"
              *canDeployOnOneThing
              ngbTooltip="Upgrade firmware"
            >
              <i class="fas fa-cloud-upload-alt" aria-hidden="true"></i>
            </a>
            <button
              class="btn btn-danger"
              (click)="removeThingFromGroup(group, thing)"
              *canEditGroupOfThings
              ngbTooltip="Remove from group"
            >
              <i class="fas fa-minus-circle" aria-hidden="true"></i>
            </button>
          </div>
        </td>
      </ng-container>
    </ng-template>
    <ng-template #thingsFilters>
      <ng-container [formGroup]="formGroup">
        <th scope="col">
          <input
            class="form-control"
            type="text"
            placeholder="Name"
            formControlName="name"
          />
        </th>
        <th scope="col">
          <input
            class="form-control"
            type="text"
            placeholder="Last Connection"
            formControlName="connectionTimeStr"
          />
        </th>
        <th scope="col">
          <input
            class="form-control"
            type="text"
            placeholder="Current Firmware"
            formControlName="currentFirmware"
          />
        </th>
        <th scope="col">
          <input
            class="form-control"
            type="text"
            placeholder="Next Firmware (reported)"
            formControlName="nextFirmwareReported"
          />
        </th>
        <th scope="col">
          <input
            class="form-control"
            type="text"
            placeholder="Next Firmware (desired)"
            formControlName="nextFirmwareDesired"
          />
        </th>
      </ng-container>
    </ng-template>
  </app-ngx-table-with-query-params-persistence>
</div>
