<div>
  <div class="card shadow mb-4">
    <div class="card-header py-3 d-flex justify-content-between">
      <h6 class="m-0 font-weight-bold text-primary">
        Real-time Activity
        {{ client?.isConnected() ? "(live feed)" : "(feed disconnected)" }}
      </h6>

      <div>
        @if(client?.isConnected()){
          <app-pause-button class="mr-2" [(isPaused)]="isPaused"></app-pause-button>
        }

        @if (messages().length > 0) {
          <button
            (click)="clearFeed()"
            type="button"
            class="mr-2 btn btn-outline-secondary"
          >
            Clear <i class="fas fa-solid fa-trash"></i>
          </button>
          <button
            (click)="exportFeed()"
            type="button"
            class="btn btn-outline-secondary"
          >
            Export <i class="fas fa-regular fa-download"></i>
          </button>
        }

      </div>

    </div>
    <div *ngIf="messages().length > 0; else noMessage" class="card-body">
      <div
        *ngFor="let message of messages(); let i = index"
        class="table-responsive"
      >
        <div class="card mb-2" key="i">
          <div
            (click)="message.collapsed = !message.collapsed"
            class="card-header"
          >
            <div class="float-left">
              <i
                [ngClass]="
                  message.collapsed ? 'fas fa-caret-right' : 'fas fa-caret-down'
                "
                aria-hidden="true"
              ></i>
            </div>
            <span class="ml-3">{{
              message.date | displayMetaVersionDate
            }}</span>
            <div class="float-right">
              <span class="badge badge-secondary">
                {{ message.topic }}
              </span>
            </div>
          </div>
          <div *ngIf="!message.collapsed" class="card-body">
            <span
              (click)="copy(inputTarget)"
              [cbContent]="inputTarget.textContent"
              class="btn btn-outline-secondary btn-sm mb-2"
              ngxClipboard
            >
              <i aria-hidden="true" class="far fa-copy"></i> Copy payload
            </span>
            <pre #inputTarget>{{ message.payload | displayHtml }}</pre>
          </div>
        </div>
      </div>
    </div>
    <ng-template #noMessage>
      <div class="card-body">
        <div class="alert alert-primary" role="alert">
          No messages received yet!
        </div>
      </div>
    </ng-template>
  </div>
</div>
