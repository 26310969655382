import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DescribeThingCommandOutput } from '@aws-sdk/client-iot';
import { BehaviorSubject, firstValueFrom, Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ConsentsService } from '../api/backend/services/consents/consents.service';
import { ThingsService } from '../api/backend/services/things/things.service';
import { DatabaseService } from '../api/database.service';
import { GroupsOfThingsService } from '../api/groups-of-things.service';
import { RegistryService } from '../api/registry.service';
import { DeleteThingComponent } from '../dialogs/delete-thing/delete-thing.component';
import { AddThingToGroupComponent } from '../groups-of-things/groups-of-things-list/add-thing-to-group/add-thing-to-group.component';
import { RemoveThingFromGroupComponent } from '../groups-of-things/groups-of-things-list/remove-thing-from-group/remove-thing-from-group.component';
import { DeviceMetaConsents } from '../models/device-meta-consents';
import { GroupOfThings } from '../models/Group-of-things.model';
import { Shadow } from '../models/shadow';
import { MacAddress, ThingData } from '../models/thingtype';
import { NotificationService } from '../shared/notification.service';
import { ProductsService } from '../api/products/products.service';
import { Product } from '../models/product';

@Component({
  selector: 'app-thing',
  templateUrl: './thing.component.html',
  styleUrls: ['./thing.component.css'],
})
export class ThingComponent implements OnInit {
  thingName?: string | null;
  thing?: DescribeThingCommandOutput;
  shadow: Shadow = new Shadow();
  macAddress?: MacAddress;
  attributeKeys?: string[];
  consents$!: Observable<DeviceMetaConsents>;
  groups$?: Observable<GroupOfThings[]>;
  refreshGroups$ = new BehaviorSubject<void>(void 0);
  creationDate?: number;
  product?: Product;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly notif: NotificationService,
    private readonly thingService: RegistryService,
    private readonly databaseService: DatabaseService,
    private readonly modal: NgbModal,
    private readonly groupsService: GroupsOfThingsService,
    private readonly thingBackendService: ThingsService,
    private readonly consentsService: ConsentsService,
    private readonly productsService: ProductsService,
  ) {}

  async ngOnInit(): Promise<void> {
    // Subscription Method
    this.activatedRoute.paramMap.subscribe((params) => {
      this.thingName = params.get('deviceId');
      this.refresh()
        .catch((err: Error) => this.notif.showError(err.message, err))
        .then(() => this.getGroups());

      this.fetchDeviceInfos().catch((err: Error) =>
        this.notif.showError(err.message, err),
      );
    });
  }

  refreshGroups(): void {
    this.refreshGroups$.next();
  }

  refresh(): Promise<[void, void]> {
    this.thing = undefined;
    this.shadow = new Shadow();

    if (!this.thingName) {
      return Promise.reject(new Error('Missing thing name'));
    } else {
      this.consents$ = this.consentsService.getConsents(this.thingName);
    }

    // getting thing information && and things's shadow
    return Promise.all([
      this.thingService.getThing(this.thingName).then(
        (res) => {
          this.thing = res;
          if (this.thing?.attributes) {
            this.attributeKeys = Object.keys(this.thing.attributes)
              .filter((_) => !_.startsWith('fw_'))
              .filter((_) => _ !== 'customGroups')
              .sort();
            this.productsService
              .getProductByRange(this.thing.attributes.range)
              .subscribe((product) => {
                this.product = product;
              });
          }
        },
        (e) => {
          this.notif.showError('Thing not found', e);
        },
      ),
      firstValueFrom(
        this.thingBackendService.getThingShadow(this.thingName),
      ).then(
        (res) => {
          this.shadow = res!;
        },
        (e) => {
          this.notif.showError('Thing shadow not found', e);
        },
      ),
    ]);
  }

  async fetchDeviceInfos(): Promise<void> {
    if (!this.thingName) {
      this.notif.showError('Missing thing name');
      return;
    }

    const thingData = ThingData.from(this.thingName);
    const device = await this.databaseService.getDevice(thingData.serialNumber);
    this.macAddress = device.macAddress;
    this.creationDate = device.creationDate;
  }

  openDeleteDialog(): void {
    const componentInstance =
      this.modal.open(DeleteThingComponent).componentInstance;
    componentInstance.thingName = this.thingName;
    componentInstance.macAddress = this.macAddress;
  }

  addThingToAGroup(thing?: DescribeThingCommandOutput): void {
    if (thing == null) {
      return;
    }

    const modal = this.modal.open(AddThingToGroupComponent, {
      backdrop: 'static',
      centered: true,
    });

    modal.componentInstance.thing = ThingData.fromDescribeResponse(thing);

    modal.closed.subscribe((refresh: ThingData) => {
      if (refresh != null) {
        this.refresh().then(() => this.refreshGroups());
      }
    });
  }

  removeThingFromGroup(
    group?: GroupOfThings,
    thing?: DescribeThingCommandOutput,
  ): void {
    if (thing == null || group == null) {
      return;
    }

    const modal = this.modal.open(RemoveThingFromGroupComponent, {
      backdrop: 'static',
      centered: true,
    });

    modal.componentInstance.group = group;
    modal.componentInstance.thing = ThingData.fromDescribeResponse(thing);

    modal.closed.subscribe(async (refresh) => {
      if (refresh) {
        this.refreshGroups();
        await this.refresh();
      }
    });
  }

  private getGroups(): void {
    this.groups$ = this.refreshGroups$.pipe(
      switchMap(() => {
        if (this.thing?.attributes?.customGroups) {
          return this.groupsService
            .getGroupsForThing(this.thing?.attributes.customGroups)
            .pipe(
              catchError((err) => {
                this.notif.showError(
                  `Error fetching thing groups : ${err.message}`,
                  err,
                );
                return of([]);
              }),
            );
        }
        return of([]);
      }),
    );
  }
}
