import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayThingArn',
})
export class DisplayThingArnPipe implements PipeTransform {
  transform(value: string): unknown {
    if (value) {
      return value.split('/')[1];
    }
    return '';
  }
}
