import { Injectable } from '@angular/core';
import {
  PutCommandOutput,
  QueryCommand,
  QueryCommandInput,
  UpdateCommandInput,
} from '@aws-sdk/lib-dynamodb';
import type { NativeAttributeValue } from '@aws-sdk/util-dynamodb';
import { combineLatest, from, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import CONFIG from '../../config';
import { AwsService } from '../lib/aws.service';
import { BrandArea } from '../models/brandarea';
import { CriteriaKey, Firmware } from '../models/firmware';
import { IotDevice } from '../models/iotdevice';
import { MetaVersionJob } from '../models/meta-version-job.model';
import { MetaVersion } from '../models/metaversion/meta-version';
import { MacAddress, SerialNumber } from '../models/thingtype';
import { Utils } from '../shared/utils';

@Injectable({
  providedIn: 'root',
})
export class DatabaseService {
  constructor(private awsService: AwsService) {}

  async listMetaVersions(activated: number): Promise<MetaVersion[]> {
    return await this.fromItemList(await this.scanMetaVersions(activated));
  }

  public async fromItemList(
    itemList: Record<string, NativeAttributeValue>[],
  ): Promise<MetaVersion[]> {
    const idToFirmwareMap = new Map<string, Firmware>();
    (await this.scanFirmwares()).forEach((_firmware) => {
      idToFirmwareMap.set(_firmware.id, _firmware);
    });

    const metaVersions = itemList.map((item) =>
      this.fromItem(item, idToFirmwareMap),
    );
    return metaVersions.sort((a, b) => (a.date > b.date ? -1 : 1));
  }

  public fromItem(
    item: Record<string, NativeAttributeValue>,
    firmwareCache: Map<string, Firmware>,
  ): MetaVersion {
    const wifiFirmware = item.wifiFirmware
      ? firmwareCache.get(item.wifiFirmware)
      : undefined;
    const uiFirmware = item.uiFirmware
      ? firmwareCache.get(item.uiFirmware)
      : undefined;

    return new MetaVersion(
      item.id,
      item.thingType,
      item.validationAccepted,
      item.priority,
      item.updateValidation,
      wifiFirmware,
      uiFirmware,
      item.date,
      item.enabledDate,
      item.activated as number,
    );
  }

  async scanMetaVersions(
    activated: number,
    lastEvaluatedKey?: Record<string, NativeAttributeValue>,
  ): Promise<Record<string, NativeAttributeValue>[]> {
    const res = await (
      await this.awsService.dynamodb()
    ).query({
      TableName: CONFIG.metaVersionsTable,
      IndexName: CONFIG.metaVersionsIndex,
      KeyConditionExpression: 'activated = :hkey',
      ExpressionAttributeValues: {
        ':hkey': activated,
      },
      ScanIndexForward: false,
      ExclusiveStartKey: lastEvaluatedKey,
    });

    let moreMetaVersions: Record<string, NativeAttributeValue>[] = [];
    if (res.LastEvaluatedKey !== undefined) {
      moreMetaVersions = await this.scanMetaVersions(
        activated,
        res.LastEvaluatedKey,
      );
    }

    return (res.Items || []).concat(moreMetaVersions);
  }

  async scanFirmwares(
    lastEvaluatedKey?: Record<string, NativeAttributeValue>,
  ): Promise<Firmware[]> {
    const params = {
      TableName: CONFIG.firmwaresTable,
      ExclusiveStartKey: lastEvaluatedKey,
    };

    const res = await (await this.awsService.dynamodb()).scan(params);

    const firmwares: Firmware[] = (res.Items || []).map((_) =>
      Firmware.parse(_),
    );
    if (res.LastEvaluatedKey) {
      const moreFirmwares = await this.scanFirmwares(res.LastEvaluatedKey);
      for (const f of moreFirmwares) {
        firmwares.push(f);
      }
    }
    return firmwares;
  }

  async listFirmwares(
    activated: number,
    thingType?: string,
    lastEvaluatedKey?: Record<string, NativeAttributeValue>,
  ): Promise<Firmware[]> {
    const input: QueryCommandInput = {
      TableName: CONFIG.firmwaresTable,
      IndexName: CONFIG.firmwaresIndex,
      KeyConditionExpression: 'activated = :hkey',
      ExpressionAttributeValues: {
        ':hkey': activated,
      },
      ScanIndexForward: false,
      ExclusiveStartKey: lastEvaluatedKey,
    };

    if (thingType && input.ExpressionAttributeValues) {
      input.KeyConditionExpression += ' AND thingType = :rkey';
      input.ExpressionAttributeValues[':rkey'] = thingType;
    }

    const command = new QueryCommand(input);
    const res = await (await this.awsService.dynamodb()).send(command);

    const firmwares: Firmware[] = (res.Items || []).map((firmware) =>
      Firmware.parse(firmware),
    );
    if (res.LastEvaluatedKey) {
      const moreFirmwares = await this.listFirmwares(
        activated,
        thingType,
        res.LastEvaluatedKey,
      );
      for (const f of moreFirmwares) {
        firmwares.push(f);
      }
    }

    return firmwares;
  }

  async search(macAddress: MacAddress): Promise<IotDevice> {
    const res = await (
      await this.awsService.dynamodb()
    ).query({
      TableName: CONFIG.iotDevicesTable,
      IndexName: CONFIG.iotDevicesIndex,
      ExpressionAttributeValues: {
        ':hkey': macAddress,
      },
      ExpressionAttributeNames: {
        '#hkey': 'macAddress',
      },
      KeyConditionExpression: '#hkey = :hkey',
    });
    if (res.Count === undefined || res.Count !== 1 || res.Items === undefined) {
      throw new Error('Invalid MAC address!');
    }
    return res.Items[0] as IotDevice;
  }

  async getFirmware(
    firmwareId: string,
  ): Promise<Record<string, NativeAttributeValue>> {
    const res = await (
      await this.awsService.dynamodb()
    ).get({ TableName: CONFIG.firmwaresTable, Key: { id: firmwareId } });
    if (!res.Item) {
      throw new Error('Firmware not found!');
    }
    return res.Item;
  }

  async deactivateFirmware(firmwareId: string): Promise<void> {
    return this.callingAwsService(firmwareId, 0);
  }

  async activateFirmware(firmwareId: string): Promise<void> {
    return this.callingAwsService(firmwareId, 1);
  }

  async callingAwsService(firmwareId: string, exp: number): Promise<void> {
    await (
      await this.awsService.dynamodb()
    ).update({
      TableName: CONFIG.firmwaresTable,
      Key: { id: firmwareId },
      UpdateExpression: 'set activated = :a',
      ExpressionAttributeValues: {
        ':a': exp,
      },
      ReturnValues: 'NONE',
    });
  }

  async listBrandAreas(
    lastEvaluatedKey?: Record<string, NativeAttributeValue>,
  ): Promise<BrandArea[]> {
    const res = await (
      await this.awsService.dynamodb()
    ).scan({
      TableName: CONFIG.firmwareBrandAreaTable,
      ExclusiveStartKey: lastEvaluatedKey,
    });
    const brandAreas = BrandArea.fromItemList(res.Items || []);
    if (res.LastEvaluatedKey !== undefined) {
      const morebrandAreas = await this.listBrandAreas(res.LastEvaluatedKey);
      for (const ba of morebrandAreas) {
        brandAreas.push(ba);
      }
    }
    return brandAreas;
  }

  async updateFirmware(
    firmware: Firmware,
    criteriaKey?: CriteriaKey,
  ): Promise<void> {
    let params: UpdateCommandInput;

    if (criteriaKey && firmware.newS3Key) {
      const s3Key = firmware.newS3Key.getS3Object();

      params = {
        TableName: CONFIG.firmwaresTable,
        Key: { id: firmware.generateId() },
        UpdateExpression: 'set #pr = :u, #s3Key.#criteriaKey= :file',
        ExpressionAttributeNames: {
          '#pr': 'date',
          '#s3Key': 's3Key',
          '#criteriaKey': criteriaKey,
        },
        ExpressionAttributeValues: {
          ':u': new Date().toISOString(),
          ':file': s3Key[criteriaKey],
        },
        ReturnValues: 'NONE',
      };

      await (await this.awsService.dynamodb()).update(params);
      return;
    } else if (firmware.s3Key) {
      params = {
        TableName: CONFIG.firmwaresTable,
        Key: { id: firmware.generateId() },
        UpdateExpression: 'set #pr = :u, #s3Key= :file',
        ExpressionAttributeNames: {
          '#pr': 'date',
          '#s3Key': 's3Key',
        },
        ExpressionAttributeValues: {
          ':u': new Date().toISOString(),
          ':file': firmware.s3Key,
        },
        ReturnValues: 'NONE',
      };

      await (await this.awsService.dynamodb()).update(params);
      return;
    }

    throw new Error('Firmware not found!');
  }

  async updateDateMetaVersion(
    metaVersionId: string,
    shouldRemoveEnabledDate: boolean,
  ): Promise<void> {
    if (shouldRemoveEnabledDate) {
      await (
        await this.awsService.dynamodb()
      ).update({
        TableName: CONFIG.metaVersionsTable,
        Key: { id: metaVersionId },
        UpdateExpression: 'remove enabledDate',
        ReturnValues: 'NONE',
      });
    } else {
      const date = new Date().toISOString();
      await (
        await this.awsService.dynamodb()
      ).update({
        TableName: CONFIG.metaVersionsTable,
        Key: { id: metaVersionId },
        UpdateExpression: 'set enabledDate = :v',
        ExpressionAttributeValues: {
          ':v': date,
        },
        ReturnValues: 'NONE',
      });
    }
  }

  async updateMetaVersion(
    metaVersionId: string,
    expressionAttributeValues: number,
  ): Promise<void> {
    await (
      await this.awsService.dynamodb()
    ).update({
      TableName: CONFIG.metaVersionsTable,
      Key: { id: metaVersionId },
      UpdateExpression: 'set activated = :a',
      ExpressionAttributeValues: {
        ':a': expressionAttributeValues,
      },
      ReturnValues: 'NONE',
    });
  }

  createFirmware(firmware: Firmware): Observable<PutCommandOutput> {
    if (!firmware.newS3Key) {
      throw new Error('Firmware not found!');
    }

    const s3Key = firmware.newS3Key.getS3Object();

    const params = {
      TableName: CONFIG.firmwaresTable,
      Item: {
        id: firmware.generateId(),
        version: firmware.version,
        type: firmware.type,
        thingType: firmware.thingType,
        s3Key,
        date: new Date().toISOString(),
        releaseNote: firmware.releaseNote,
        activated: firmware.activated,
        criteriaType: firmware.criteriaType,
      },
      ConditionExpression: 'attribute_not_exists(id)',
    };

    return from(this.awsService.dynamodb()).pipe(
      switchMap((dynamodb) => dynamodb.put(params)),
    );
  }

  getFirmwares(
    firmwareIds: string[],
  ): Observable<Record<string, NativeAttributeValue>[]> {
    const chunkedFirmwareIds = firmwareIds.reduce(
      (batchMap: string[][], firmwareId, i) => {
        // creates an index for the final array, to group firmwares by chunks of 100
        const batchNum = Math.floor(i / 100); // batchGet is limited to 100 items
        if (batchMap[batchNum] === undefined) {
          batchMap[batchNum] = [];
        }
        batchMap[batchNum].push(firmwareId);
        return batchMap;
      },
      [],
    );

    const chunkedResponsePromises = chunkedFirmwareIds.map((chunk) =>
      this.awsService.dynamodb().then((dynamodb) => {
        return dynamodb
          .batchGet({
            RequestItems: {
              [CONFIG.firmwaresTable]: {
                Keys: chunk.map((id) => ({ id })),
              },
            },
          })
          .then((response) => {
            if (response.Responses) {
              return response.Responses[CONFIG.firmwaresTable];
            }
            return [];
          });
      }),
    );

    return from(Promise.all(chunkedResponsePromises)).pipe(
      map((chunkedResponses) => {
        const aggregate: Array<Record<string, NativeAttributeValue>> = [];
        chunkedResponses.forEach((res) => aggregate.push(...res));
        return aggregate;
      }),
    );
  }

  async getDevice(serialNumber: SerialNumber): Promise<IotDevice> {
    const res = await (
      await this.awsService.dynamodb()
    ).get({
      TableName: CONFIG.iotDevicesTable,
      Key: { serialnumber: serialNumber },
    });
    if (!res.Item) {
      throw new Error('Device not found in database');
    }
    return res.Item as IotDevice;
  }

  /**
   * Inserts a metaversion <> job link into dynamoDB
   *
   * @param job the data to be inserted
   */
  insertJobForMetaversion(job: MetaVersionJob): Observable<PutCommandOutput> {
    return from(this.awsService.dynamodb()).pipe(
      switchMap((dynamodb) =>
        dynamodb.put({
          TableName: CONFIG.metaversionJobsTable,
          Item: {
            ...job,
            date: job.date.toISOString(),
          },
        }),
      ),
    );
  }

  /**
   * Finds the list of jobs corresponding to a metaversion
   *
   * @param metaversionId the metaversion ID to look for
   */
  getJobsForMetaVersion(metaversionId: string): Observable<MetaVersionJob[]> {
    return from(this.awsService.dynamodb()).pipe(
      switchMap((dynamodb) =>
        dynamodb.query({
          TableName: CONFIG.metaversionJobsTable,
          IndexName: CONFIG.metaversionJobsIndex,
          KeyConditionExpression: '#metaversionId = :metaversionId',
          ExpressionAttributeValues: {
            ':metaversionId': metaversionId,
          },
          ExpressionAttributeNames: {
            '#metaversionId': 'metaversionId',
          },
        }),
      ),
      map((output): MetaVersionJob[] =>
        MetaVersionJob.mapDynamoOutputToMetaversionJobs(output.Items ?? []),
      ),
    );
  }

  /**
   * Returns the metaversion id associated with each of the input job ids by querying dynamoDB
   *
   * @param jobsIds the job ids we want to fetch the metaversion for
   */
  getMetaversionForJobs(jobsIds: string[]): Observable<MetaVersionJob[]> {
    // We can only pass 100 ids at once to batchGet
    return combineLatest(
      Utils.splitArrayToFixedSizeChunks(jobsIds, 100).map((jobIdsChunk) =>
        this.batchGetMetaversionsForJobs(jobIdsChunk),
      ),
    ).pipe(
      map((batchGetResults: MetaVersionJob[][]): MetaVersionJob[] =>
        batchGetResults.flat(),
      ),
    );
  }

  private batchGetMetaversionsForJobs(
    jobsIds: string[],
  ): Observable<MetaVersionJob[]> {
    return from(this.awsService.dynamodb()).pipe(
      switchMap((dynamodb) =>
        dynamodb.batchGet({
          RequestItems: {
            [CONFIG.metaversionJobsTable]: {
              Keys: jobsIds.map((jobId) => ({ jobId })),
            },
          },
        }),
      ),
      map((output): MetaVersionJob[] =>
        MetaVersionJob.mapDynamoOutputToMetaversionJobs(
          output.Responses?.[CONFIG.metaversionJobsTable] ?? [],
        ),
      ),
    );
  }
}
