<!-- Begin Page Content -->
<div class="container-fluid">
  <!-- Page Heading -->
  <h1 class="text-gray-800">Search</h1>
  <!-- Page Body -->
  <div class="card shadow mb-4">
    <!-- Begin of filter bar -->
    <div class="card-header">
      <div class="d-flex flex-row justify-content-between w-100">
        <form action="" class="form-inline">
          <div class="form-row align-items-center">
            <div class="col-auto ml-1">
              <select
                class="form-select"
                aria-label="Default select example"
                id="thingType"
                name="thingType"
                [disabled]="isLoading"
                [(ngModel)]="thingValue"
                (change)="onChange($event)"
              >
                <option>Thing Type</option>
                <option *ngFor="let thingType of thingTypes">
                  {{ thingType }}
                </option>
              </select>
            </div>
            <div class="col-auto">
              <input
                class="form-control"
                style="min-width: 400px"
                id="macAddress"
                name="macAddress"
                type="text"
                placeholder="MAC Address, Serial Number, or Thing Name"
                ngModel
                #macAddress="ngModel"
                (change)="onChangeSearchValue($event)"
              />
            </div>
            <div class="col-auto">
              <button
                class="btn btn-primary"
                (click)="search(macAddress.value)"
              >
                Search
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!-- end of filter bar -->
    <!-- Begin content -->
    <app-thing-list-table
      [devices]="devices"
      [thingType]="thingValue"
      [columns]="columns"
      [configuration]="configuration"
      [formGroupLocalFilters]="formGroupLocalFilters"
      [pageNumber]="pageNumber"
      [disablePreviousPage]="disablePreviousPage"
      [disableNextPage]="disableNextPage"
      [previousDevices]="previousDevices.bind(this)"
      [nextDevices]="nextDevices.bind(this)"
    >
    </app-thing-list-table>
  </div>
</div>
