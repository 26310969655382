import { UtilsService } from '../../lib/utils.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayHtml',
})
export class DisplayHtmlPipe implements PipeTransform {
  constructor(private utlisService: UtilsService) {}

  transform(value: string): unknown {
    if (value) {
      return this.utlisService.displayHtml(value);
    }
    return '';
  }
}
