<div class="card-body">
  <div class="d-flex" *canSeeConnectedThings>
    <h6
      class="font-weight-bold text-primary"
      *ngIf="thingsCount$ | async as thingCount"
    >
      Total count of
      <u>{{ connected ? "connected" : "disconnected" }}</u> devices :
      {{ thingCount }}
    </h6>
    <small class="ml-2 text-muted font-italic"
      >Column filters are limited to the currently displayed Things</small
    >
  </div>
  <app-ngx-table-with-query-params-persistence
    [columns]="columns"
    [configuration]="configuration"
    [data]="devices"
    [filtersFormGroup]="formGroupLocalFilters"
    [filtersTemplate]="thingsFilters"
  >
    <ng-template let-rowDevice>
      <ng-container *ngIf="typeDevice(rowDevice) as device">
        <td class="text-nowrap">
          <span class="d-flex flex-row justify-content-between">
            <span>{{ device.name }}</span>
            <span
              class="btn btn-outline-secondary btn-sm ml-2"
              (click)="copieKey(device.name)"
            >
              <i class="far fa-copy" aria-hidden="true"></i>
            </span>
          </span>
        </td>
        <td>{{ device.connectionTimeStr || "-" }}</td>
        <td>{{ device.currentFirmware || "-" }}</td>
        <td>{{ device.nextFirmwareReported || "-" }}</td>
        <td>{{ device.nextFirmwareDesired || "-" }}</td>
        <td>
          <div class="btn-group">
            <a
              routerLink="/things/{{ device.name }}"
              class="btn btn-eye"
              *canSeeThingDetails
            >
              <i class="far fa-eye" aria-hidden="true"></i>
            </a>
            <a
              routerLink="/metaversions/thing/{{ device.name }}"
              class="btn btn-primary"
              *canDeployOnOneThing
              ngbTooltip="Upgrade firmware"
            >
              <i class="fas fa-cloud-upload-alt" aria-hidden="true"></i>
            </a>
            <button
              class="btn btn-success"
              (click)="addThingToAGroup(device)"
              *canEditGroupOfThings
              ngbTooltip="Add to group"
            >
              <i class="fas fa-sitemap" aria-hidden="true"></i>
            </button>
          </div>
        </td>
      </ng-container>
    </ng-template>
    <ng-template #thingsFilters>
      <ng-container [formGroup]="formGroupLocalFilters">
        <th scope="col">
          <input
            class="form-control"
            type="text"
            placeholder="Name"
            formControlName="name"
          />
        </th>
        <th scope="col">
          <input
            class="form-control"
            type="text"
            placeholder="Last Connection"
            formControlName="connectionTimeStr"
          />
        </th>
        <th scope="col">
          <input
            class="form-control"
            type="text"
            placeholder="Current Firmware"
            formControlName="currentFirmware"
          />
        </th>
        <th scope="col">
          <input
            class="form-control"
            type="text"
            placeholder="Next Firmware (reported)"
            formControlName="nextFirmwareReported"
          />
        </th>
        <th scope="col">
          <input
            class="form-control"
            type="text"
            placeholder="Next Firmware (desired)"
            formControlName="nextFirmwareDesired"
          />
        </th>
      </ng-container>
    </ng-template>
  </app-ngx-table-with-query-params-persistence>
</div>
<div
  class="mb-3 d-flex align-items-center justify-content-center"
  *ngIf="devices?.length"
>
  <button
    class="btn btn-primary mr-2"
    [disabled]="disablePreviousPage"
    (click)="previousDevices()"
  >
    Previous
  </button>
  <span class="mr-2">Page n°{{ pageNumber + 1 }}</span>
  <button
    class="btn btn-primary"
    [disabled]="disableNextPage"
    (click)="nextDevices($event)"
  >
    Next
  </button>
</div>
