<!-- Begin Page Content -->
<div class="container-fluid">
  <!-- Page Heading -->
  <div class="d-flex justify-content-between align-items-center">
    <div class="d-flex align-items-center">
      <h1 class="text-gray-800">
        Firmware <strong>{{ firmwareId }}</strong>
      </h1>
      <app-audit-creator
        *ngIf="this.firmware"
        [type]="AuditType.FIRMWARE"
        [action]="AuditAction.UPLOAD"
        [resourceId]="this.firmware.id"
      />
    </div>
    <div *ngIf="this.firmware">
      @if (this.firmware.activated) {
        <div class="float-right" *canEditFirmware>
          <button
            [disabled]="isLoading"
            class="btn btn-danger mr-1"
            (click)="firmwareUpdateState(false, firmware.id)"
          >
            <i class="fas fa-trash mr-1" aria-hidden="true"></i>
            Deprecate
          </button>
          <button
            [disabled]="isLoading"
            class="btn btn-danger"
            (click)="clearNextFirmwareShadows(firmware.id)"
          >
            <i class="fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
            Clear "NextFirmware" Shadow
          </button>
        </div>
      } @else {
        <div
          class="float-right d-flex flex-row align-items-center"
          (click)="firmwareUpdateState(true, firmware.id)"
        >
          <i class="fas fa-exclamation-triangle mr-1" aria-hidden="true"></i>
          <div class="mr-2">This firmware is deprecated</div>
          <button
            [disabled]="isLoading"
            class="btn btn-success float-right"
            *canEditFirmware
          >
            <i class="fas fa-trash-restore mr-1" aria-hidden="true"></i>
            Restore
          </button>
        </div>
      }
    </div>
  </div>
  <div class="card shadow mb-4">
    <form *ngIf="this.firmware; else elseBlock" class="card-body">
      <div class="form-row">
        <div class="col" *ngIf="this.firmware.type !== 'ui'; else elseBlock">
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <label for="date">Date</label>
                <input
                  id="date"
                  class="form-control"
                  type="text"
                  disabled
                  value="{{ firmware.date | displayFormatDate }}"
                />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="thingType-2">Thing Type</label>
                <input
                  class="form-control"
                  id="thingType-2"
                  name="thingType"
                  type="text"
                  disabled
                  value="{{ firmware.thingType }}"
                />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="type-2">Type</label>
                <input
                  class="form-control"
                  id="type-2"
                  name="type"
                  type="text"
                  disabled
                  value="{{ firmware.type }}"
                />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="criteria-2">Criteria</label>
                <input
                  class="form-control"
                  id="criteria-2"
                  name="criteria"
                  type="text"
                  disabled
                  value="{{ firmware.criteriaType }}"
                />
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <label for="version-2">Version</label>
                <input
                  class="form-control"
                  id="version-2"
                  name="version"
                  type="text"
                  disabled
                  value="{{ firmware.version }}"
                />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="fileName">File Name</label>
                <div class="input-group" (click)="onDownloadFirmware(firmware)">
                  <input
                    class="form-control"
                    id="fileName"
                    name="fileName"
                    type="text"
                    disabled
                    value="{{ firmware | displayFirmwareFileName }}"
                  />
                  <button
                    class="btn input-group-append"
                    [ngClass]="{
                      'btn-primary': firmware.isSigned(),
                      'btn-secondary text-white': !firmware.isSigned(),
                    }"
                    [disabled]="!firmware.isSigned()"
                  >
                    <i
                      class="fas fa-cloud-download-alt m-auto"
                      *ngIf="
                        (this.loadingPresignUrl$ | async) === false;
                        else downloadLoader
                      "
                      aria-hidden="true"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="signingInfo">Signing Info</label>
            <input
              class="form-control"
              id="signingInfo"
              name="signingInfo"
              type="text"
              disabled
              value="{{ firmware | displayFirmwareSigningInfo }}"
            />
          </div>
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <label for="bootloader-2">Bootloader</label>
                <input
                  class="form-control"
                  id="bootloader-2"
                  name="bootloader"
                  type="text"
                  disabled
                  value="{{ firmware | displayFirmwareBootloader }}"
                />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="signingError">Signing Error</label>
                <input
                  class="form-control"
                  id="signingError"
                  name="signingError"
                  type="text"
                  disabled
                  value="{{ firmware | displayFirmwareSigningError }}"
                />
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="releaseNote">Release Note</label>
            <textarea
              class="form-control releaseNote"
              id="releaseNote-2"
              name="releaseNote"
              disabled
              rows="{5}"
              >{{ firmware.releaseNote }}</textarea
            >
          </div>
        </div>
        <ng-template #elseBlock>
          <div class="col">
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <label for="version">Version</label>
                  <input
                    class="form-control"
                    id="version"
                    name="version"
                    type="text"
                    disabled
                    value="{{ firmware.version }}"
                  />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="thingType">Thing Type</label>
                  <input
                    class="form-control"
                    id="thingType"
                    name="thingType"
                    type="text"
                    disabled
                    value="{{ firmware.thingType }}"
                  />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="type">Type</label>
                  <input
                    class="form-control"
                    id="type"
                    name="type"
                    type="text"
                    disabled
                    value="{{ firmware.type }}"
                  />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="criteria">Criteria</label>
                  <input
                    class="form-control"
                    id="criteria"
                    name="criteria"
                    type="text"
                    disabled
                    value="{{ firmware.criteriaType }}"
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="releaseNote">Release Note</label>
              <textarea
                class="form-control releaseNote"
                id="releaseNote"
                name="releaseNote"
                disabled
                rows="{5}"
                value="{{ firmware.releaseNote }}"
              ></textarea>
            </div>
            <div class="card shadow mb-4">
              <div class="card-header py-3">
                <h6 class="m-0 font-weight-bold text-primary">
                  Criteria & Brand area List
                </h6>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <app-firmware-table
                    [firmware]="firmware"
                    [shouldDisplayRange]="mustHaveRange"
                    [shouldDisplayCmmf]="mustHaveCmmf"
                    [shouldDisplayIndice]="mustHaveIndice"
                    [shouldDisplayBrandArea]="shouldDisplayBrandArea"
                  ></app-firmware-table>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
        <div class="col-md-auto">
          <div class="separator"></div>
        </div>
        <ng-container *canEditFirmware>
          <div class="col" *ngIf="this.firmware && this.firmware.newS3Key">
            <h2 class="h3 mb-4 text-gray-800">Upload A New Firmware File</h2>
            <div class="form-group">
              <input
                #fileInput
                class="form-control-file"
                id="firmwareFile"
                name="firmwareFile"
                type="file"
                (change)="extractFirmwareData()"
                [disabled]="this.isLoading"
              />
              <app-spinner-small *ngIf="extractingFirmwareData"
                >Extracting data</app-spinner-small
              >
              <br />
            </div>
            <div class="col-auto">
              <div class="form-check form-switch ml-2 mb-2">
                <input
                  class="form-check-input"
                  id="signed"
                  name="signed"
                  type="checkbox"
                  [(ngModel)]="firmwareSigned"
                  [disabled]="this.isLoading"
                  (change)="onChangeCheck($event)"
                />
                <label class="form-check-label" for="signed">
                  This firmware is already signed
                </label>
              </div>
            </div>
            <div
              class="form-group"
              [hidden]="!this.firmware || !this.firmwareSigned"
            >
              <label for="bootloader">BootLoader</label>
              <input
                class="form-control"
                id="bootloader"
                name="bootloader"
                type="text"
                placeholder="Example: 1.0.0"
                [disabled]="this.isLoading"
                (change)="onChangeBoatLoader($event)"
              />
            </div>
            <div class="form-group" *ngIf="mustHaveRange">
              <label for="range">Range</label>
              <input
                class="form-control"
                id="range"
                name="range"
                type="text"
                placeholder="Example: BC90"
                (change)="onChangeRange($event)"
                [disabled]="isLoading"
              />
            </div>
            <div class="form-group" *ngIf="mustHaveCmmf">
              <label f="cmmf">CMMF</label>
              <app-chips-input
                [disable]="isLoading"
                [inputId]="'cmmf'"
                [placeholder]="'Example: 8372032750'"
                [validationRegex]="'^[0-9]{10}$'"
                [validationErrorMessage]="
                  'CMMFs must be 10 digits only.<br>Invalid values : #tagNames'
                "
                (addingChip)="onAddingCMMF(this.firmware, $event)"
                (removingChip)="onRemovingCMMF(this.firmware, $event)"
              ></app-chips-input>
              <small class="form-text text-muted font-italic"
                >You can write or copy/paste several CMMFs, separated by a
                space, a new line or / , ; - : or by pressing Enter</small
              >
              <small
                *ngIf="additionalCmmfs?.length"
                class="form-text text-warning font-weight-bold"
                >The created "Firmware File" will be duplicated to assign each
                CMMF</small
              >
            </div>
            <div class="form-group" *ngIf="mustHaveIndice">
              <label for="indice">Indice</label>
              <input
                #indiceInput
                class="form-control"
                id="indice"
                name="indice"
                type="text"
                placeholder="Example: 1"
                (change)="onChangeIndice($event)"
                [disabled]="isLoading"
              />
            </div>
            <div
              class="form-group"
              *ngIf="brandAreas.length && shouldDisplayBrandArea"
            >
              <label for="brandArea">Choose a brand-area</label>
              <select
                class="form-select"
                id="brandArea"
                name="brandArea"
                [disabled]="this.isLoading"
                (change)="onChange($event)"
              >
                <option disabled selected>Brand Area</option>
                <option
                  *ngFor="let brandArea of brandAreas"
                  value="{{ brandArea.key }}"
                >
                  {{ brandArea.key }} {{ brandArea.description }}
                </option>
              </select>
            </div>
          </div>
        </ng-container>
      </div>
      <button
        class="btn btn-primary float-right btn-update"
        [disabled]="this.isLoading"
        *canEditFirmware
        type="submit"
        (click)="saveFirmare()"
      >
        <i class="fas fa-save" aria-hidden="true"></i>
        Update
      </button>
      <a class="btn btn-secondary text-white float-right" (click)="cancel()"
        >Cancel</a
      >
      <br />
      <br />
    </form>
    <ng-template #elseBlock>
      <div class="alert alert-primary">Loading...</div>
    </ng-template>
  </div>
  <!-- end of table -->
</div>
<!-- /.container-fluid -->

<ng-template #downloadLoader>
  <app-spinner-small></app-spinner-small>
</ng-template>

<ng-template #confirmClearShadowsModal let-modal>
  <div class="modal-body">
    <h2>Confirmation</h2>
    <hr />
    <p>Are you sure?</p>
    <p>
      In case of numerous targeted Things, this may fail and require to be done
      <strong>manually</strong>.
    </p>
    <hr />
    <button
      (click)="modal.close(true)"
      class="btn btn-danger float-right button-model"
    >
      Clear shadows
    </button>
    <button
      (click)="modal.close(false)"
      class="btn btn-secondary float-right mr-1"
    >
      Cancel
    </button>
  </div>
</ng-template>
