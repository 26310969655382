import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GetFirmwareDownloadPresignurlResponseBody } from '../../../../models/backend/metaversion/get-firmware-download-presignurl-response-body';
import {
  DeleteNextFirmwareShadowsResponse,
  ExtractedFirmwareData,
  Firmware,
} from '../../../../models/firmware';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FirmwareService {
  private readonly backendUrl = environment.backendUrl;

  constructor(private readonly httpClient: HttpClient) {}

  getFirmwareGetPresignUrl(
    firmware: Firmware,
    firmwareS3Key: string,
  ): Observable<string> {
    return this.httpClient
      .get<GetFirmwareDownloadPresignurlResponseBody>(
        `${this.backendUrl}/signed-firmwares/${firmware.id}/binaries/${firmwareS3Key}/presign-url`,
      )
      .pipe(map((res) => res.url));
  }

  downloadFromPresignUrl(url: string): Observable<Blob> {
    return this.httpClient.get(url, { responseType: 'blob' });
  }

  getExtractedFirmwareData(
    presignedUrlFirmware: string,
    thingType?: string,
  ): Observable<ExtractedFirmwareData> {
    return this.httpClient.get<ExtractedFirmwareData>(
      `${this.backendUrl}/firmwares/extract-data`,
      {
        params: {
          firmwareUrl: presignedUrlFirmware,
          thingType: thingType as string,
        },
      },
    );
  }

  deleteNextFirmwareShadows(
    firmwareId: string,
  ): Observable<DeleteNextFirmwareShadowsResponse> {
    return this.httpClient.delete<DeleteNextFirmwareShadowsResponse>(
      `${this.backendUrl}/nextfirmwares/${firmwareId}`,
    );
  }
}
