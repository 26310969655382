import { NgModule } from '@angular/core';
import {CreateDeleteFactoryFirmwareDirective} from './directives/create-delete-factory-firmware.directive';
import {CreateEditMetaversionDirective} from './directives/create-edit-metaversion.directive';
import {DeleteProductLogsDirective} from './directives/delete-product-logs.directive';
import {DeleteThingDirective} from './directives/delete-thing.directive';
import {DeployOnGroupOfThingsDirective} from './directives/deploy-on-group-of-things.directive';
import { DeployOnOneThingDirective } from './directives/deploy-on-one-thing.directive';
import { DeployWithCriteriaDirective } from './directives/deploy-with-criteria.directive';
import { DeprecateMetaversionDirective } from './directives/deprecate-metaversion.directive';
import { EditFirmwareDirective } from './directives/edit-firmware.directive';
import {EditGroupOfThingsDirective} from './directives/edit-group-of-things.directive';
import {EnableProductLogsDirective} from './directives/enable-product-logs.directive';
import {ListDeploymentsDirective} from './directives/list-deployments.directive';
import {ListFactoryFirmwaresDirective} from './directives/list-factory-firmwares.directive';
import { ListFirmwaresDirective } from './directives/list-firmwares.directive';
import {ListGroupsOfThingsDirective} from './directives/list-group-of-things.directive';
import { ListMetaversionsDirective } from './directives/list-metaversions.directive';
import {ReadProductLogsDirective} from './directives/read-product-logs.directive';
import {SearchThingDirective} from './directives/search-thing.directive';
import {SeeBillingDirective} from './directives/see-billing.directive';
import {SeeConnectedThingsDirective} from './directives/see-connected-things.directive';
import {SeeDeploymentDetailsDirective} from './directives/see-deployment-details.directive';
import {SeeFactoryFirmwareDetailsDirective} from './directives/see-factory-firmware-details.directive';
import { SeeFirmwareDetailsDirective } from './directives/see-firmware-details.directive';
import { SeeGroupsOfThingsDetailsDirective } from './directives/see-group-of-things-details.directive';
import {SeeManifestDirective} from './directives/see-manifest.directive';
import {SeeMetaversionDetailsDirective} from './directives/see-metaversion-details.directive';
import {SeeStatisticsDirective} from './directives/see-statistics.directive';
import {SeeThingDetailsDirective} from './directives/see-thing-details.directive';

@NgModule({
  declarations: [],
  imports: [
    DeployOnOneThingDirective,
    DeployWithCriteriaDirective,
    DeleteThingDirective,
    ListGroupsOfThingsDirective,
    EditGroupOfThingsDirective,
    ReadProductLogsDirective,
    ListFactoryFirmwaresDirective,
    SeeBillingDirective,
    CreateEditMetaversionDirective,
    SeeMetaversionDetailsDirective,
    DeprecateMetaversionDirective,
    DeployOnGroupOfThingsDirective,
    EditFirmwareDirective,
    SeeConnectedThingsDirective,
    SeeThingDetailsDirective,
    SearchThingDirective,
    ListFirmwaresDirective,
    ListMetaversionsDirective,
    SeeStatisticsDirective,
    SeeFirmwareDetailsDirective,
    ListDeploymentsDirective,
    SeeDeploymentDetailsDirective,
    SeeGroupsOfThingsDetailsDirective,
    CreateDeleteFactoryFirmwareDirective,
    SeeFactoryFirmwareDetailsDirective,
    DeleteProductLogsDirective,
    EnableProductLogsDirective,
    SeeManifestDirective,
  ],
  exports: [
    DeployOnOneThingDirective,
    DeployWithCriteriaDirective,
    DeleteThingDirective,
    ListGroupsOfThingsDirective,
    EditGroupOfThingsDirective,
    ReadProductLogsDirective,
    ListFactoryFirmwaresDirective,
    SeeBillingDirective,
    CreateEditMetaversionDirective,
    SeeMetaversionDetailsDirective,
    DeprecateMetaversionDirective,
    DeployOnGroupOfThingsDirective,
    EditFirmwareDirective,
    SeeConnectedThingsDirective,
    SeeThingDetailsDirective,
    SearchThingDirective,
    ListFirmwaresDirective,
    ListMetaversionsDirective,
    SeeStatisticsDirective,
    SeeFirmwareDetailsDirective,
    ListDeploymentsDirective,
    SeeDeploymentDetailsDirective,
    SeeGroupsOfThingsDetailsDirective,
    CreateDeleteFactoryFirmwareDirective,
    SeeFactoryFirmwareDetailsDirective,
    DeleteProductLogsDirective,
    EnableProductLogsDirective,
    SeeManifestDirective,
  ],
})
export class UserRightsManagementModule {}
