import { RegistryService } from '../api/registry.service';
import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../shared/notification.service';
import { ThingTypesSelect } from '../models/thingtypesselect';
import { SearchIndexCommandOutput } from '@aws-sdk/client-iot';
import { ThingTypesService } from '../api/thing-types.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Columns, Config, DefaultConfig } from 'ngx-easy-table';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ThingListDisplay } from '../models/thing-list-display.model';
import { IotStatisticsService } from '../api/iot-statistics.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  devices: ThingListDisplay[] = [];
  filters: ThingTypesSelect[] = [{ id: '1', name: 'Thing Type' }];
  filterValue: string = this.filters[0].name;
  connected = true;
  pageNumber = 0;
  disablePreviousPage = true;
  disableNextPage = false;
  isLoading = false;
  queryString?: string;
  searchResponses: SearchIndexCommandOutput[] = [];

  thingsCount$?: Observable<number | string>;

  readonly columns = [
    { key: 'name', title: 'Name', width: '25%' },
    { key: 'connectionTimeStr', title: 'Last Connection', width: '10%' },
    { key: 'currentFirmware', title: 'Current Firmware', width: '10%' },
    {
      key: 'nextFirmwareReported',
      title: 'Next Firmware (reported)',
      searchEnabled: true,
      width: '10%',
    },
    {
      key: 'nextFirmwareDesired',
      title: 'Next Firmware (desired)',
      orderEnabled: true,
      width: '10%',
    },
    {
      key: 'actions',
      title: 'Actions',
      orderEnabled: false,
      searchEnabled: false,
      width: '4%',
    },
  ] as Columns[];

  configuration: Config = {
    ...DefaultConfig,
    searchEnabled: true,
    paginationEnabled: false,
    rows: 1000000,
    orderEnabled: true,
    isLoading: this.isLoading,
  };

  formGroupLocalFilters = new UntypedFormGroup({
    name: new UntypedFormControl(),
    connectionTimeStr: new UntypedFormControl(),
    currentFirmware: new UntypedFormControl(),
    nextFirmwareReported: new UntypedFormControl(),
    nextFirmwareDesired: new UntypedFormControl(),
  });

  constructor(
    private notif: NotificationService,
    private homeService: RegistryService,
    private thingTypesService: ThingTypesService,
    private iotStatisticsService: IotStatisticsService,
  ) {}

  ngOnInit(): void {
    this.search().catch((err: Error) => this.notif.showError(err.message, err));
    this.getThingTypes();
  }

  resetFilter(): void {
    this.connected = true;
    this.formGroupLocalFilters.reset();
    this.filterValue = this.filters[0].name;
    this.search().catch((err: Error) => this.notif.showError(err.message, err));
  }

  onChange(): void {
    this.search().catch((err: Error) => this.notif.showError(err.message, err));
  }

  onChangeTypeThing(event: Event): void {
    this.filterValue = (event.target as HTMLOptionElement).value;
    this.search().catch((err: Error) => this.notif.showError(err.message, err));
  }

  async nextDevices(event: Event): Promise<void> {
    event.preventDefault();
    const searchResponse: SearchIndexCommandOutput =
      this.searchResponses[this.pageNumber];
    const nextPage: number = this.pageNumber + 1;

    if (searchResponse.nextToken) {
      this.setIsLoading(true);

      if (this.searchResponses.length > nextPage) {
        // Devices already listed
        this.setIsLoading(false);
        this.devices = ThingListDisplay.fromThingDocuments(
          this.searchResponses[nextPage].things,
        );
        this.pageNumber = this.pageNumber + 1;
        this.disableNextPage = !this.searchResponses[nextPage].nextToken;
        this.disablePreviousPage = false;
      } else {
        try {
          const nextPageResponse = await this.homeService.paginateListDevices(
            this.queryString,
            searchResponse.nextToken,
          );
          this.searchResponses.push(nextPageResponse);
          this.disableNextPage = !nextPageResponse.nextToken;

          this.devices = ThingListDisplay.fromThingDocuments(
            this.searchResponses[nextPage].things,
          );
        } catch (e) {
          this.notif.showError((e as Error).message, e);
          this.setIsLoading(false);
          return;
        }
        this.setIsLoading(false);
        this.pageNumber = this.pageNumber + 1;
        this.disablePreviousPage = false;
      }
    }

    window.scrollTo(0, 0);
  }

  getThingTypes(): void {
    this.thingTypesService
      .getThingTypes()
      ?.pipe(
        catchError((err) => {
          this.notif.showError(err.message, err);
          return of([]);
        }),
      )
      .subscribe((thingTypes) => {
        let index = 2;
        for (const type of thingTypes) {
          this.filters.push({ id: index.toString(), name: type });
          index++;
        }
      });
  }

  previousDevices(): void {
    if (this.pageNumber > 0) {
      this.pageNumber--;
      this.devices = ThingListDisplay.fromThingDocuments(
        this.searchResponses[this.pageNumber].things,
      );
      window.scrollTo(0, 0);
      if (this.pageNumber === 0) {
        this.disablePreviousPage = true;
      }
      this.disableNextPage = false;
    }
  }

  private setIsLoading(val: boolean): void {
    this.isLoading = val;
    if (this.configuration) {
      this.configuration.isLoading = val;
    }
  }

  private async search(): Promise<void> {
    const searchResponses: SearchIndexCommandOutput[] = [];
    this.setIsLoading(true);

    this.queryString = this.makeSearchQueryString();
    if (this.queryString === '') {
      this.setIsLoading(false);
      return;
    }

    this.thingsCount$ = this.iotStatisticsService
      .getThingCount({
        thingType:
          this.filterValue !== 'Thing Type' ? this.filterValue : undefined,
        connected: this.connected,
      })
      .pipe(
        catchError((err: Error) => {
          this.notif.showError(
            `Error fetching things count : ${err.message}`,
            err,
          );
          return of('error');
        }),
      );

    try {
      const searchResponse = await this.homeService.paginateListDevices(
        this.queryString,
      );
      searchResponses.push(searchResponse);
      this.disableNextPage = !searchResponse.nextToken;

      this.devices = ThingListDisplay.fromThingDocuments(
        searchResponses[0].things,
      );

      if (searchResponses[0].things?.length === 0) {
        this.setIsLoading(false);
      }
    } catch (e) {
      this.notif.showError((e as Error).message, e);
      this.setIsLoading(false);
      return;
    }

    this.searchResponses = searchResponses;
    this.pageNumber = 0;
    this.disablePreviousPage = true;
    this.setIsLoading(false);
  }

  private makeSearchQueryString(): string {
    const queryParams = [];

    // Connected param
    queryParams.push('connectivity.connected:' + this.connected);

    // Thing type
    if (this.filterValue !== 'Thing Type') {
      queryParams.push('thingTypeName:' + this.filterValue);
    }

    return queryParams.join(' AND ');
  }
}
