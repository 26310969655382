@if (loading$ | async) {
  <app-spinner-small></app-spinner-small>
}
@if (createdBy$ | async; as created) {
  @if (created.userId) {
    <span class="ml-2"
      >{{ phrasing }}
      <span class="badge badge-secondary">{{
        created.userId | displayCreatorName
      }}</span></span
    >
  }
}
